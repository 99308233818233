import { useWSysHub } from '@coimbra-its/websys-ux-core';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, Contract } from 'ethers';
import { useEffect } from 'react';
import { useSunStore } from '../store/sunSlice';
import { payout_address_sdbn2, payout_address_sdbn2plus, token_address, token_address_sdbn2plus, usdt_address, useCreateWeb3Modal } from './config';
import { token_abi } from './token.abi';
import { token_abi_sdbn2plus } from './token.SDBN2plus.abi';
import { usdt_abi } from './usdt.abi';
import { payout_abi_sdbn2 } from './payout.SDBN2.abi';
import { payout_abi_sdbn2plus } from './payout.SDBN2Plus.abi';



export function useWeb3() {

	const signInHub = useWSysHub();
	useCreateWeb3Modal(signInHub);



	const { address, chainId, isConnected } = useWeb3ModalAccount();

	const connectedWallet = useSunStore(state => state.connectedWallet);
	const initialRequest = useSunStore(state => state.initialRequest);
	const setWalletDisconnected = useSunStore(state => state.walletDisconnected);
	const setWalletConnected = useSunStore(state => state.walletConnected);

	const loadBalance = useSunStore(state => state.loadBalance);
	//const loadOwnerInfo = useSunStore(state => state.loadOwnerInfo);


	useEffect(() => {
		const reallyConnected = isConnected && !!address && chainId && walletProvider;
		console.log('ehun::::', address, chainId, isConnected, connectedWallet);

		const walletEqualsStored = () => {
			return !!connectedWallet
				&& (connectedWallet.address === address)
				&& (connectedWallet.chainId === chainId)
				;
		}
		if (!reallyConnected && connectedWallet) {
			setWalletDisconnected();
		} else if (reallyConnected && !walletEqualsStored()) {
			(async () => {
				const ethersProvider = new BrowserProvider(walletProvider);
				const signer = await ethersProvider.getSigner();

				const TokenContract = new Contract(token_address, token_abi, signer);
				const Token2PlusContract = new Contract(token_address_sdbn2plus, token_abi_sdbn2plus , signer);
				const USDTContract = new Contract(usdt_address, usdt_abi, signer);
				const PayoutContractSDBN2 = new Contract(payout_address_sdbn2, payout_abi_sdbn2, signer);
				const PayoutContractSDBN2Plus = new Contract(payout_address_sdbn2plus, payout_abi_sdbn2plus, signer);

				setWalletConnected({
					walletProvider,
					chainId,
					address,
					ethersProvider,
					
					signer,

					TokenContract, 
					Token2PlusContract,
					USDTContract, 
					
					PayoutContractSDBN2,					
					PayoutContractSDBN2Plus,					
				})
			})();
		}
	}, [address, chainId, isConnected, connectedWallet])

	const { walletProvider } = useWeb3ModalProvider();

	useEffect(() => {
		if (connectedWallet && !initialRequest) {
			(async () => {
				await loadBalance();
				//await loadOwnerInfo();
			})();
			
		}

	}, [connectedWallet, initialRequest])


	// -------------------------------------------------------------------
	// -------------------------------------------------------------------

}