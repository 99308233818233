import { Box, Input, InputGroup, InputRightAddon, VStack } from '@chakra-ui/react';
import { useWSysHub, useWSysPage } from "@coimbra-its/websys-ux-core";
import { useEffect, useState } from 'react';
import { SunBlock, SunButton, SunPage, SunValue, dispYearMonth, formatAmount } from "../components/Sun";
import { SunState, useSunStore } from '../store/sunSlice';
import { payout_address_sdbn2 } from '../web3/config';


export function PayoutSDBN2() {
    const page = useWSysPage({ title: 'Payout SDBN2', maxCols: 2 });

    const hub = useWSysHub();

    const connectedWallet = useSunStore((state: SunState) => state.connectedWallet);
    const loadBalance = useSunStore((state: SunState) => state.loadBalance);
    const USDTBalance = useSunStore((state: SunState) => state.walletAmounts.USDTBalance);
    //const USDTApprovedLimit = useSunStore((state: SunState) => state.walletAmounts.USDTApprovedLimit); ?? itt inkább a payout_ -ra vett allowance kéne
    const [approvedAmount, setApprovedAmount] = useState(0)

    const [lastDate, setLastDate] = useState(0);
    const [lastDatePaid, setLastDatePaid] = useState(false);
    const [nextDate, setNextDate] = useState(0);
    const [amountStr, setAmountStr] = useState('0');
    const [amount, setAmount] = useState(0);
    useEffect(() => {
        setAmount((parseFloat(amountStr) || 0) * 1e18)
    }, [amountStr]);

    const doLoad = async () => {
        hub.tryCatch(async  () => {

            if (!connectedWallet)
                throw 'Wallet not connected';

            const _approvedAmount = await connectedWallet.USDTContract.allowance(connectedWallet.address, payout_address_sdbn2);
            setApprovedAmount(_approvedAmount);

            const lastDateEnable = Number(await connectedWallet.PayoutContractSDBN2.lastDate());
            setLastDate(lastDateEnable);

            const paidM = await connectedWallet.PayoutContractSDBN2.paidMonths(BigInt(lastDateEnable));
            setLastDatePaid(paidM);

            if (!paidM) {
                setNextDate(0);
            } else {

                const lastYear = Math.floor(lastDateEnable / 100);
                const lastMonth = lastDateEnable - 100 * lastYear;
                let nextMonth = lastMonth + 1;
                let nextYear = lastYear;
                if (nextMonth > 12) {
                    nextMonth -= 12;
                    nextYear++;
                }
                setNextDate(nextYear * 100 + nextMonth);
            }



        })
    }

    useEffect(() => {
        if (connectedWallet)
            doLoad();
    }, [connectedWallet])


    const doApprove = async () => {
        hub.tryCatch(async  () => {

            if (!connectedWallet)
                throw 'Wallet not connected';

            let value = BigInt(amount);

            // Végrehajtjuk az approve metódust
            const tx = await connectedWallet.USDTContract.approve(payout_address_sdbn2, value);

            // Várunk a tranzakció megerősítésére
            const receipt = await tx.wait();

            // Tranzakció hash kiírása
            console.log('Transaction hash:', receipt.hash);

            await loadBalance();
        });

    }

    const doPayout = async () => {
        hub.tryCatch(async  () => {

            if (!connectedWallet)
                throw 'Wallet not connected';

            if (!nextDate || amount < 0)
                return;

            // Végrehajtjuk az approve metódust
            const tx = await connectedWallet.PayoutContractSDBN2.addDateAndAmount(BigInt(nextDate), BigInt(amount));

            // Várunk a tranzakció megerősítésére
            const receipt = await tx.wait();

            // Tranzakció hash kiírása
            console.log('Transaction hash:', receipt.hash);

            await loadBalance();
            await doLoad();

        });

    }


    const doSync = async () => {
        hub.tryCatch(async  () => {


            if (!connectedWallet)
                throw 'Wallet not connected';

            let tx = await connectedWallet.PayoutContractSDBN2.syncBalances();
            const receipt = await tx.wait();

            // Tranzakció hash kiírása
            console.log('Transaction hash:', receipt.hash);

            await loadBalance();
            await doLoad();

        });

    }



    return <SunPage page={page} hubs={[hub]}>

        <VStack alignItems='stretch' justifyContent='center' h='100%' gap={9} px='4em'>
            {connectedWallet && <>
                <SunBlock header='Last date'>
                    <SunValue dim={lastDatePaid ? 'Paid' : 'Not paid'}>
                        {dispYearMonth(lastDate)}
                    </SunValue>
                </SunBlock>
                <SunBlock header='Next date'>
                    <SunValue>
                        {nextDate
                            ? dispYearMonth(nextDate)
                            : 'Last date is not ready!'
                        }
                    </SunValue>
                </SunBlock>
                <SunBlock header='Your balance'>
                    <SunValue dim='USDT'>{formatAmount(USDTBalance)}</SunValue>
                </SunBlock>
                <SunBlock header='Approved limit'>
                    <SunValue dim='USDT'>{formatAmount(approvedAmount)}</SunValue>
                </SunBlock>
                <SunBlock header='Payout amount' py={3}>
                    <InputGroup>
                        <Input
                            value={amountStr} onChange={e => setAmountStr(e.target.value)}
                            border='none' textAlign='right'
                        />
                        <InputRightAddon>USDT</InputRightAddon>
                    </InputGroup>
                </SunBlock>

                <SunBlock display='flex' flexDir='row' justifyContent='space-evenly' alignItems='center' py={3}>
                    <SunButton
                        isDisabled={!lastDatePaid || amount <= 0}
                        variant='black' onClick={doApprove}  >
                        1) Approve
                    </SunButton>
                    <SunButton
                        isDisabled={!lastDatePaid || amount <= 0 || Number(approvedAmount) < amount}
                        onClick={doPayout}>
                        2) Pay
                    </SunButton>
                </SunBlock>

                <SunBlock py={3} flexDir='column'>
                    <Box p={3} textAlign='center'>
                        {lastDatePaid
                            ? `Last date (${dispYearMonth(lastDate)}) is synchronized`
                            : `Last date (${dispYearMonth(lastDate)}) is NOT synchronized`
                        }
                    </Box>
                    <SunButton onClick={doSync} isDisabled={lastDatePaid} >3) Synchronize</SunButton>
                </SunBlock>


            </>}
        </VStack>

    </SunPage>
}