import { VStack } from '@chakra-ui/react';
import { useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";
import React from 'react';
import { SunBlock, SunButton, SunLinkButton, SunPage } from "../components/Sun";
import { SunState, useSunStore } from '../store/sunSlice';
import { ADMINS } from '../web3/config';


export interface DashboardProps {
    walletBox: React.ReactNode;
}


export function Dashboard({ walletBox }: DashboardProps) {
    const path = useWSysPath();
    const page = useWSysPage({ title: 'SDBN2 & SDBN2+', maxCols: 2, onClosed: false })
    const connectedWallet = useSunStore((state: SunState) => state.connectedWallet);

    const isAdmin = connectedWallet && ADMINS.indexOf(connectedWallet.address) >= 0;

    return <SunPage page={page} >

        <VStack alignItems='stretch' justifyContent='center' h='100%' gap={9} px='4em'>
            {walletBox}
            {!!connectedWallet && <>
                <SunLinkButton onClick={() => path.open('wallet')}>Wallet</SunLinkButton>
                {/*<SunLinkButton onClick={() => path.open('referral')}>Referral program</SunLinkButton>*/}
                <SunLinkButton onClick={() => path.open('withdraw_sdbn2')}>Withdraw SDBN2</SunLinkButton>
                <SunLinkButton onClick={() => path.open('withdraw_sdbn2plus')}>Withdraw SDBN2+</SunLinkButton>
            </>}
            {!!connectedWallet && isAdmin && <>
                <SunBlock header='Owner tools' >
                    <SunButton variant='outline' onClick={() => path.open('admin_payout_sdbn2')}>Payout SDBN2</SunButton>
                    <SunButton variant='outline' onClick={() => path.open('admin_payout_sdbn2plus')}>Payout SDBN2+</SunButton>
                </SunBlock>
            </>}
        </VStack>
    </SunPage >
}


