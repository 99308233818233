import { useToast } from '@chakra-ui/react';
import { CellProps, WSysTable, useWSysHub, useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";
import { useEffect } from 'react';
import { SunBlock, SunButton, SunPage, SunStack, SunValue, dispYearMonth, formatAmount } from "../components/Sun";
import { ISunPayout, SunState, useSunStore } from '../store/sunSlice';

const CELLS: CellProps<ISunPayout>[] = [
    { title: 'Date', elem: row => dispYearMonth(row.date) },
    //{ title: 'Status', elem: row => row.status },
    { title: 'Amount', elem: row => formatAmount(row.userAmount) },
]

export function WithdrawSDBN2Plus() {

    const path = useWSysPath();
    const hub = useWSysHub();

    const page = useWSysPage({ title: 'Withdraw SDBN2+', maxCols: 2 });

    const connectedWallet = useSunStore((state: SunState) => state.connectedWallet);
    const payoutInfo = useSunStore(state => state.payoutInfoSDBN2Plus);
    const loadPayouts = useSunStore(state => state.loadPayoutsSDBN2Plus);
    const loadBalance = useSunStore(state => state.loadBalance);


    const toast = useToast();

    const doLoadPayouts = async () => {
        hub.tryCatch(async () => {
            await loadPayouts();
            toast({ status: 'success', description: 'payouts loaded' })
        });
    }

    useEffect(() => {
        if (connectedWallet) {
            hub.tryCatch(async () => {
                loadPayouts();
            });
        }
    }, [connectedWallet])

    const doWithdraw = async () => {

        hub.tryCatch(async () => {
            await connectedWallet?.PayoutContractSDBN2Plus.withdraw();
            await loadPayouts();
            await loadBalance();

        });
    }

    return <SunPage page={page} hubs={[hub]} >
        <SunStack mb={4}>
            <SunBlock header='Sum payouts'>
                <SunValue dim='USDT'>{formatAmount(payoutInfo.userSum)}</SunValue>
            </SunBlock>
            <SunBlock header='Already withdrawn'>
                <SunValue dim='USDT'>{formatAmount(payoutInfo.userWithdrawn)}</SunValue>
            </SunBlock>
            <SunBlock header='Withdraw now' py={3}>
                <SunButton variant='primary' onClick={doWithdraw} >{`Withdraw ${formatAmount(payoutInfo.withdrawAble)} USDT`}</SunButton>
            </SunBlock>


            <SunButton variant='outline' onClick={doLoadPayouts} >Reload payouts</SunButton>

        </SunStack>

        <WSysTable
            cells={CELLS}
            rows={payoutInfo.payouts}
        />

    </SunPage>
}