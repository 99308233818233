import { BigNumberish, BrowserProvider, Contract, Eip1193Provider, JsonRpcSigner } from 'ethers';
import { create } from 'zustand';
import { usdt_address } from '../web3/config';



export interface ISunConnectedWallet {
    walletProvider: Eip1193Provider,
    address: `0x${string}`,
    chainId: number,
    ethersProvider: BrowserProvider,
    signer: JsonRpcSigner,

    TokenContract: Contract,
    Token2PlusContract: Contract,
    USDTContract: Contract,
    //SaleContract: Contract,
    PayoutContractSDBN2: Contract,
    PayoutContractSDBN2Plus: Contract,
}

export interface ISunWalletAmounts {
    TokenBalance: BigNumberish;
    Token2PlusBalance: BigNumberish;
    USDTBalance: BigNumberish;
    //USDTApprovedLimit: BigNumberish;

    BNBBalance: BigNumberish;

    /*lvl1Bonus: BigNumberish;
    lvl2Bonus: BigNumberish;
    lvl3Bonus: BigNumberish;

    walletRecoveryId: string;
    myReferralName: string;
    parentReferralSavedAddress: string;
    parentReferralSavedName: string;*/
}


export interface ISunPayout {
    id: string;
    status: string;
    userAmount: BigNumberish;
    date: number;
}

export interface ISunPayoutInfo {
    payouts: ISunPayout[];
    userSum: BigNumberish;
    userWithdrawn: BigNumberish;
    withdrawAble: BigNumberish;
}



export interface SunState {
    isLoading: boolean,
    walletError: string,
    setIsLoading: (v: boolean) => void;
    setWalletError: (v: string) => void;


    // --------------------------------
    connectedWallet: ISunConnectedWallet | null,
    walletDisconnected: () => void,
    walletConnected: (sunWalletProps: ISunConnectedWallet) => void,

    initialRequest: boolean;
    setInitialRequest: (v: boolean) => void;

    walletAmounts: ISunWalletAmounts;
    setWalletAmounts: (v: Partial<ISunWalletAmounts>) => void;
    
    payoutInfoSDBN2: ISunPayoutInfo;
    payoutInfoSDBN2Plus: ISunPayoutInfo;


    // --------------
    loadBalance: () => void;
    //loadOwnerInfo: () => void;
    loadPayoutsSDBN2: () => void;
    loadPayoutsSDBN2Plus: () => void;
    /*loadPayoutsRemote: WSysUseResult<any>;
    setLoadPayoutsRemote: (v : Partial<WSysUseResult<any>>) => void;*/


}

export const useSunStore = create<SunState>()(
    (set, get) => ({
        isLoading: false,
        walletError: '',
        setIsLoading: (v) => set(state => ({ isLoading: v })),
        setWalletError: (v) => set(state => ({ walletError: v })),
        initialRequest: false,
        setInitialRequest: (v) => set(state => ({ initialRequest: v })),

        // ----------------------------------

        connectedWallet: null,
        walletDisconnected: () => set(state => ({
            connectedWallet: null,
            initialRequest: false,
        })),
        walletConnected: (sunWalletProps) => set(state => ({
            connectedWallet: sunWalletProps,
            initialRequest: false,
        })),

        // ------

        walletAmounts: {
            TokenBalance: BigInt(0),
            Token2PlusBalance: BigInt(0),
            USDTBalance: BigInt(0),
            BNBBalance: BigInt(0),
        },
        setWalletAmounts: (v) => set(state => ({
            walletAmounts: Object.assign({}, get().walletAmounts, v)
        })),

        /*ownerInfo: {
            allSold: BigInt(0),
            lvl1SumBonus: BigInt(0),
            lvl2SumBonus: BigInt(0),
            lvl3SumBonus: BigInt(0),
            allSumBonus: BigInt(0),
        },
        setOwnerInfo: (v) => set(state => ({
            ownerInfo: Object.assign({}, get().ownerInfo, v)
        })),*/

        payoutInfoSDBN2: {
            payouts: new Array<ISunPayout>(),
            userSum: BigInt(0),
            userWithdrawn: BigInt(0),
            withdrawAble: BigInt(0),
        },
        
        payoutInfoSDBN2Plus: {
            payouts: new Array<ISunPayout>(),
            userSum: BigInt(0),
            userWithdrawn: BigInt(0),
            withdrawAble: BigInt(0),
        },
        


        // ==========================================================
        // -------------------- ACTIONS -----------------------------
        // ==========================================================
        // --------------------
        loadBalance: async () => {
            try {
                get().setIsLoading(true);
                get().setWalletError('');
                const wallet = get().connectedWallet;
                if (!wallet)
                    throw Error('User disconnected');
                console.log('---------------------------------------');
                console.log(wallet.address, usdt_address);


                const TokenBalance = await wallet.TokenContract.balanceOf(wallet.address);
                const Token2PlusBalance = await wallet.Token2PlusContract.balanceOf(wallet.address);
                const USDTBalance = await wallet.USDTContract.balanceOf(wallet.address);
                const BNBBalance = await wallet.ethersProvider.getBalance(wallet.address);

                //const USDTApprovedLimit = await wallet.USDTContract.allowance(wallet.address, payout_address);
                /*

                const lvl1Bonus = await wallet.SaleContract.referralLvl1Bonus(wallet.address);
                const lvl2Bonus = await wallet.SaleContract.referralLvl2Bonus(wallet.address);
                const lvl3Bonus = await wallet.SaleContract.referralLvl3Bonus(wallet.address);

                const walletRecoveryId = await wallet.SaleContract.walletConfirm(wallet.address);

                const myReferralName = await wallet.SaleContract.referralAddressName(wallet.address);
                const parentReferralSavedAddress = await wallet.SaleContract.referralTree(wallet.address);
                const parentReferralSavedName = await wallet.SaleContract.referralAddressName(wallet.address);*/

                //console.log('Tokenbalance:', TokenBalance, typeof TokenBalance);
                set((state) => ({
                    walletAmounts: {
                        
                        
                        TokenBalance,
                        Token2PlusBalance,
                        USDTBalance,
                        //USDTApprovedLimit,
                        BNBBalance,

                        /*lvl1Bonus,
                        lvl2Bonus,
                        lvl3Bonus,

                        walletRecoveryId,
                        myReferralName,
                        parentReferralSavedAddress,
                        parentReferralSavedName,*/
                    }
                }))
            } catch (ex) {
                get().setWalletError('' + ex);
            } finally {
                get().setIsLoading(false);
            }
        },

        // --------------------
        loadPayoutsSDBN2: async () => {
            console.log('----load payout SDBN2 ----')
            const wallet = get().connectedWallet;
            if (!wallet)
                throw Error('User disconnected');
            let payouts = new Array<ISunPayout>();

            let wDates = await wallet.PayoutContractSDBN2.getDates();
            console.log('dates', wDates);

            let wAmounts = await wallet.PayoutContractSDBN2.getUserData(wallet.address);
            console.log('amounts', wAmounts);

            let userSum = await wallet.PayoutContractSDBN2.userSum(wallet.address);
            console.log('userSum', userSum);

            let userWithdrawn = await wallet.PayoutContractSDBN2.userWithdrawn(wallet.address);
            console.log('userWithdrawn', userWithdrawn);

            let withdrawAble = userSum - userWithdrawn;
            console.log('withdrawAble', withdrawAble);

            for (let id in wDates) {
                let date = wDates[id];
                let amount = wAmounts[id];
                payouts.push({
                    id, userAmount: amount, date, status: ''
                });
            }
            console.log('payouts:', payouts);
            set((state) => ({
                payoutInfoSDBN2: {
                    payouts, userSum, userWithdrawn, withdrawAble
                }
            }))
        },


        // --------------------
        loadPayoutsSDBN2Plus: async () => {
            console.log('----load payout SDBN2Plus ----')
            const wallet = get().connectedWallet;
            if (!wallet)
                throw Error('User disconnected');
            let payouts = new Array<ISunPayout>();

            let wDates = await wallet.PayoutContractSDBN2Plus.getDates();
            console.log('dates', wDates);

            let wAmounts = await wallet.PayoutContractSDBN2Plus.getUserData(wallet.address);
            console.log('amounts', wAmounts);

            let userSum = await wallet.PayoutContractSDBN2Plus.userSum(wallet.address);
            console.log('userSum', userSum);

            let userWithdrawn = await wallet.PayoutContractSDBN2Plus.userWithdrawn(wallet.address);
            console.log('userWithdrawn', userWithdrawn);

            let withdrawAble = userSum - userWithdrawn;
            console.log('withdrawAble', withdrawAble);

            for (let id in wDates) {
                let date = wDates[id];
                let amount = wAmounts[id];
                payouts.push({
                    id, userAmount: amount, date, status: ''
                });
            }
            console.log('payouts:', payouts);
            set((state) => ({
                payoutInfoSDBN2Plus: {
                    payouts, userSum, userWithdrawn, withdrawAble
                }
            }))
        },


    }),
)